<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    max-width="520px"
    persistent
    overlay-color="defaultBg"
  >
    <v-card class="mainBg">
      <v-form class="pb-10" ref="form" v-model="validForm">
        <div class="defaultColor--text headline pl-15 py-15">
          Reset password
        </div>
        
        <div class="px-15">
          <div class="pb-7">
            <span class="secondaryColor--text">New password</span>
          </div>
          
          <v-text-field
            v-model.trim="formData.password"
            :append-icon="seePassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="seePassword ? 'text' : 'password'"
            background-color="defaultBg"
            flat
            solo
            :rules="passwordRules"
            @click:append="seePassword = !seePassword"
          />
        </div>
        
        <div class="px-15">
          <div class="pb-7">
            <span class="secondaryColor--text">Confirmation password</span>
          </div>
          
          <v-text-field
            v-model.trim="formData.password_confirmation"
            :append-icon="seePasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
            :type="seePasswordConfirmation ? 'text' : 'password'"
            background-color="defaultBg"
            flat
            solo
            :rules="passwordConfirmationRules"
            @click:append="seePasswordConfirmation = !seePasswordConfirmation"
          />
        </div>
        
        <div class="d-flex justify-end px-15 py-10">
          <v-btn
            class="subtitle-1 accentColor--text error mr-10"
            large
            depressed
            height="50"
            @click="closeDialog"
          >Cancel</v-btn>
          
          <v-btn
            class="subtitle-1 accentColor--text secondary"
            large
            depressed
            height="50"
            type="submit"
            @click.prevent="submitForm"
            :loading="loading"
          >Reset</v-btn>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'DialogResetPassword',
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    validForm: true,
    formData: {
      password: null,
      password_confirmation: null,
    },
    seePassword: false,
    seePasswordConfirmation: false,
    passwordRules: [],
    passwordConfirmationRules: [],
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    ...mapState({
      loading: state => state.users.loadingAfterAction,
      actionSuccess: state => state.users.resetPasswordSuccess,
    }),
  },
  methods: {
    ...mapActions({
      resetPassword: 'users/RESET_PASSWORD',
    }),
    async submitForm() {
      await this.formValidation()

      if (this.$refs.form.validate()) {
        this.resetPassword({
          formData: this.formData,
          id: this.user.id
        })
      }
    },
    formValidation() {
      this.passwordRules = [
        v => !!v || 'This field is required',
        v => !!v && v.length > 5 || 'Password must be at least 6 characters'
      ]
      this.passwordConfirmationRules = [
        v => !!v || 'This field is required',
        v => !!v && v === this.formData.password || 'Passwords must match'
      ]
    },
    closeDialog() {
      this.show = false
    },
  },
  watch: {
    show: {
      handler(value) {
        if (!value) {
          Object.keys(this.formData).forEach(name => this.formData[name] = null)
          this.seePassword = false
          this.seePasswordConfirmation = false
          this.passwordRules = []
          this.passwordConfirmationRules = []
        }
      }
    },
    actionSuccess: {
      handler(value) {
        if (value) {
          this.closeDialog()
        }
      }
    },
  },
}
</script>
