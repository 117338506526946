<template>
  <div class="py-10 py-md-20 px-9 px-md-18">
    <div class="d-flex flex-wrap justify-space-between align-center">
      <div class="d-flex pb-6 pb-md-12 pr-6">
        <v-btn
          class="defaultBg accentColor--text subtitle-1 px-10 mr-8"
          height="48"
          depressed
          @click="openDialogCreate"
        >
          Create User
        </v-btn>
        
        <v-btn
          class="defaultBg"
          height="48"
          min-width="58"
          depressed
          @click="loadUsers"
        >
          <v-icon color="accentColor">mdi-reload</v-icon>
        </v-btn>
      </div>
      
      <div class="input-header-container pb-6 pb-md-12">
        <v-text-field
          v-model="search"
          class="accentColor--text"
          background-color="defaultBg"
          label="Search"
          flat
          solo
          hide-details
          :readonly="loading"
          @input="serverSearch($event, 'loadUsers')"
        >
          <template #prepend-inner>
            <v-icon
              v-if="search.length"
              color="primary"
              @click="clearSearch('loadUsers')"
            >mdi-close</v-icon>
            <v-icon
              v-else
              color="defaultColor"
            >mdi-magnify</v-icon>
          </template>
        </v-text-field>
      </div>
    </div>
    
    <v-data-table
      class="defaultBg"
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :custom-sort="() => items"
      loader-height="2"
      mobile-breakpoint="0"
      hide-default-footer
      disable-pagination
    >
      <template #item.name="{ item }">
        <span class="text-no-wrap">{{ item.name }}</span>
      </template>
      
      <template #item.actions="{ item }">
        <TooltipAction bottom message="Edit" v-if="item.id !== 1">
          <template #icon>
            <v-icon color="accentColor" @click="openDialogEdit(item)">mdi-pencil</v-icon>
          </template>
        </TooltipAction>
  
        <TooltipAction bottom message="Reset password">
          <template #icon>
            <v-icon color="accentColor" @click="openDialogResetPassword(item)">mdi-lock-reset</v-icon>
          </template>
        </TooltipAction>
        
        <TooltipAction bottom message="Delete" v-if="item.id !== 1">
          <template #icon>
            <v-icon color="accentColor" @click="openDialogDelete(item)">mdi-delete</v-icon>
          </template>
        </TooltipAction>
      </template>
    </v-data-table>
    
    <v-pagination
      class="paginationBody text-center pt-6"
      v-if="items.length && !(dataPagination['last_page'] === 1)"
      v-model="dataPagination['current_page']"
      :length="dataPagination['last_page']"
      :page="dataPagination['current_page']"
      total-visible="8"
      :disabled="loading"
      @input="pageNext($event, 'loadUsers')"
    />
    
    <DialogUser
      v-model="dialogUser"
      :user="editUser"
    />
    
    <DialogResetPassword
      v-model="dialogResetPassword"
      :user="editUser"
    />
    
    <DialogConfirm
      v-model="dialogConfirm"
      :title="dialogTitle"
      :subTitle="dialogSubTitle"
      :message="dialogMessage"
      :loading="actionLoading"
      @successAction="goToDeleteUser"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import serverSearch from '@/mixins/serverSearch'
import changeOnPage from '@/mixins/changeOnPage'
import pageNext from '@/mixins/pageNext'

import DialogUser from '@/components/dialogs/DialogUser'
import DialogResetPassword from '@/components/dialogs/DialogResetPassword'
import DialogConfirm from '@/components/dialogs/DialogConfirm'
import TooltipAction from '@/components/tooltip/TooltipAction'

export default {
  name: 'Users',
  components: { DialogUser, DialogResetPassword, DialogConfirm, TooltipAction },
  mixins: [ serverSearch, changeOnPage, pageNext ],
  data: () => ({
    options: {},
    headers: [
      { text: 'Name', value: 'name', sortId: 'byName' },
      { text: 'Email', value: 'email', sortId: 'byEmail' },
      { text: 'Actions', value: 'actions', align: 'end', width: 150, sortable: false },
    ],
    dialogUser: false,
    dialogResetPassword: false,
    editUser: null,
    dialogConfirm: false,
    deleteUserId: null,
    dialogTitle: null,
    dialogSubTitle: null,
    dialogMessage: null,
    serverSorting: null
  }),
  computed: {
    ...mapState({
      items: state => state.users.items,
      loading: state => state.users.loading,
      actionSuccess: state => state.users.actionSuccess,
      actionLoading: state => state.users.loadingAfterAction,
      dataPagination: state => state.users.dataPagination,
    })
  },
  methods: {
    ...mapActions({
      getUsers: 'users/GET_USERS',
      deleteUser: 'users/DELETE_USER',
      resetState: 'users/RESET_STATE',
    }),
    loadUsers() {
      const sortBy = this.serverSorting ? { ...this.serverSorting } : {}
      const params = {
        ...this.pageParams,
        search: this.search ? this.search : undefined,
        ...sortBy
      }
      this.getUsers(params)
    },
    openDialogCreate() {
      this.editUser = null
      this.dialogUser = true
    },
    openDialogEdit(item) {
      this.editUser = item
      this.dialogUser = true
    },
    openDialogResetPassword(item) {
      this.editUser = item
      this.dialogResetPassword = true
    },
    openDialogDelete(item) {
      this.deleteUserId = item.id
      this.dialogTitle = 'Delete'
      this.dialogSubTitle = `${item.email}`
      this.dialogMessage = 'Are you sure you want to delete this User?'
      this.dialogConfirm = true
    },
    goToDeleteUser() {
      this.deleteUser(this.deleteUserId)
    },
  },
  watch: {
    options: {
      handler(sortOptions) {
        if (sortOptions.sortBy.length) {
          const sortBy = this.headers.find(item => item.value === sortOptions.sortBy[0])
          this.serverSorting = { [sortBy.sortId]: sortOptions.sortDesc[0] ? 'desc' : 'asc' }
        } else {
          this.serverSorting = null
        }
        this.loadUsers()
      }
    },
    actionSuccess: {
      handler(value) {
        if (value) {
          if (this.items.length === 1 && this.deleteBuyerId && this.pageParams.page !== 1) {
            this.pageParams.page = this.pageParams.page - 1
            this.deleteUserId = null
          }
          this.loadUsers()
        }
      }
    }
  },
  beforeDestroy() {
    this.resetState()
  }
}
</script>
